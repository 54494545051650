/*
 * @Description:获取用户信息
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-05-19 11:18:02
 * @LastEditors: zhoucheng
 */
import Vue from '@/main.js'
import axios from 'axios'
import qs from 'qs'

// 获取code
function queryCode () {
  var reg = new RegExp('(^|&)' + 'code' + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

/**
 * @name: 获取用户信息
 * @description: 根据 code 获取用户信息（包含userToken） 异步 返回 Promise
 * @param {type} {*}
 * @return {type} {*} Promise.then(用户信息包含userToken)
 */
export default function () {
  // 返回一个 Promise
  return new Promise((resolve, reject) => {
    // 如果userInfo 已存在 则直接返回 sessionStorage
    if (sessionStorage.getItem('userInfo') !== 'undefined') {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (userInfo && userInfo.userToken) {
        resolve(userInfo)
        return
      }
    }
    const info = {
      code: queryCode(),
      type: 'officialAccount'
    }
    // 根据code请求用户信息
    axios({
      url: '/cqparkApi/login/ConsumerLoginController/appLoginWithWxCode',
      method: 'post',
      data: qs.stringify(info),
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      const search = window.location.href
      const reg = new RegExp('#/+(.*)')
      const r = search.match(reg)
      // 状态码 4001 用户首次登录 跳转登录页 绑定手机号
      if (res.data.resultCode === '4001') {
        Vue.$router.push({
          path: '/login',
          query: {
            openId: res.data.resultEntity.wechatId,
            page: `/${r[1]}`
          }
        })
      } else {
        const userInfo = JSON.stringify(res.data.resultEntity)
        sessionStorage.setItem('userInfo', userInfo)
        // 返回用户信息
        resolve(userInfo)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

<!--
 * @Description:立柱 扫码支付 payScanCode  有关订单页面
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-06 15:11:31
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          <img :src="require('@/assets/commonPage/orderIcon.png')"
               alt="">
          <span class="wenzi">订单详情</span>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">订单号</van-col>
          <van-col class="details">{{orderSequence}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="billRuleCode">
          <van-col class="name">计费规则</van-col>
          <van-col style="color:red">
            <span v-if="billRuleCode===1">半小时1元</span>
            <span v-if="billRuleCode===2">1小时2元</span>
          </van-col>
          <van-col style="color:blue;margin-left: 20px"
                   @click="queryBillingRuleDetail">详情</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">收款单位</van-col>
          <van-col class="details">中交四公局(重庆)城市建设发展有限公司</van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount}}</span>
            <span v-if="showFree===2"> - </span>
            <span style="color:red;font-size:20px;"
                  v-if="showFree===2">{{orderList.receivableMoneyAmount}}</span>
            元
          </van-col>
        </van-row>
      </van-row>
      <van-row>
        <van-button type="info"
                    class="payBtn"
                    @click="handleClickPay">支付</van-button>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
// import formatDuration from '@/utils/duration'
import parkStopTime from '@/utils/parkStopTime'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      deviceId: '',
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      // 订单详情列表
      orderList: {},
      // 路由车牌号
      plateNumber: '',
      orderSequence: '',
      billingRuleDefId: '',
      billRuleCode: 0,
      parkId: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // https://officialaccount.cqybxm.com/#/?code=saoj/?page=payScanCode/?deviceId=123
    const currentHref = window.location.href
    this.deviceId = currentHref.split('deviceId=')[1]
    getUserInfo().then(() => {
      this.getOrderList()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取订单详情列表
    getOrderList () {
      const info = {
        deviceId: this.deviceId
      }
      this.$payScanCode.queryOrderByDeviceId(info).then(res => {
        if (res.resultEntity === undefined || res.resultEntity === null || res.resultEntity === '') {
          this.$toast('该车暂无订单')
          this.$router.push('/parkPayForOthers')
        } else {
          this.orderList = res.resultEntity
          // 应收金额等于=应收-实收
          this.orderList.receivableMoneyAmount =
            Number((this.orderList.receivableMoneyAmount - this.orderList.receivedMoneyAmount) / 100).toFixed(2)
          // this.orderList.parkDuration = formatDuration(this.orderList.parkDuration)
          // 如果无离场时间 需要自己算
          if (!this.orderList.leaveTime) {
            this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
          } else {
            this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.orderList.leaveTime)
          }
          this.orderSequence = res.resultEntity.orderSequence
          this.billingRuleDefId = res.resultEntity.billingRuleDefId
          if (this.billingRuleDefId === 'V7') {
            this.billRuleCode = 1
          } else if (this.billingRuleDefId === '1') {
            this.billRuleCode = 2
          }
          this.parkId = res.resultEntity.parkId
          this.queryForFree()
        }
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$payForParking.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({
        name: 'billRuleDetail',
        query: { billRuleCode: this.billRuleCode }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { parkId: this.parkId }
      // })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { billingRuleDefId: this.billingRuleDefId }
      // })
    },
    // 支付按钮
    handleClickPay () {
      // 不收费直接返回，订单结束
      if (this.showFree === 2) {
        const info = {
          orderSequence: this.orderSequence
        }
        this.$payForParking.completeOrderFreeV2(info).then(res => {
          this.$toast('本单免费')
          this.$router.go(-1)
        })
      } else if (this.showFree === 1) {
        if (Number(this.orderList.receivableMoneyAmount) !== 0 && this.orderSequence) {
          this.$router.push({
            name: 'choosePayStyle',
            query: {
              payMoney: this.orderList.receivableMoneyAmount,
              payOrder: this.orderSequence,
              plateNumber: this.orderList.plateNumber,
              numberPlateColorCode: this.orderList.numberPlateColorCode
            }
          })
        } else if (Number(this.orderList.receivableMoneyAmount) === 0) {
          const that = this
          const info1 = {
            orderSequence: that.orderSequence
          }
          that.$commonPage.chargeBill(info1).then(response => {
            const _that = that
            _that.orderList.receivableMoneyAmount =
              Number((response.resultEntity.receivableMoneyAmount - response.resultEntity.receivedMoneyAmount) / 100).toFixed(2)
            // _that.orderList.parkDuration = formatDuration(response.resultEntity.parkDuration)
            // 如果无离场时间 需要自己算
            if (!_that.orderList.leaveTime) {
              _that.orderList.parkDuration = parkStopTime(_that.orderList.entranceTime, _that.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
            } else {
              _that.orderList.parkDuration = parkStopTime(_that.orderList.entranceTime, _that.orderList.leaveTime)
            }
            if (Number(response.resultEntity.receivableMoneyAmount) === 0) {
              _that.$payForParking.completeOrderFreeV2(info1).then(() => {
                _that.$toast('支付成功!')
                _that.$router.push('/parkPayForOthers')
              })
            } else {
              _that.$router.push({
                name: 'choosePayStyle',
                query: {
                  payMoney: _that.orderList.receivableMoneyAmountt,
                  payOrder: _that.orderSequence,
                  plateNumber: _that.orderList.plateNumber,
                  numberPlateColorCode: _that.orderList.numberPlateColorCode
                }
              })
            }
          })
        }
      }
    }
    // 顶部返回按钮
    // handleClickTopBack () {
    //   this.$router.go(-1)
    // }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 20px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      // height: 100px;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .wenzi {
          margin-left: 10px;
        }
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: calc(100% - 80px - 12px);
          min-height: 33.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 150px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .payBtn {
      width: 93.6%;
      margin: 0 3.2%;
      border-radius: 5px;
      background: #19a9fc;
      font-size: 17px;
    }
  }
}
</style>
